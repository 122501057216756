<div class="col-md-6 user-container">
  <h2 style="margin: auto"> User Details</h2>
  <button class="btn btn-danger" style="width:100px" (click)="addUser()"> Add User</button>
  <table class="table table-striped">
    <thead>
    <tr>
      <th class="hidden">Id</th>
      <th>FirstName</th>
      <th>LastName</th>
      <th>UserName</th>
      <th>Age</th>
      <th>Salary</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td class="hidden">{{user.id}}</td>
      <td>{{user.firstName}}</td>
      <td>{{user.lastName}}</td>
      <td>{{user.username}}</td>
      <td>{{user.age}}</td>
      <td>{{user.salary}}</td>
      <td><button class="btn btn-success" (click)="deleteUser(user)"> Delete</button>
        <button class="btn btn-success" (click)="editUser(user)" style="margin-left: 20px;"> Edit</button></td>
    </tr>
    </tbody>
  </table>
</div>
